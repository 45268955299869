import React from 'react';
import { Badge } from 'antd';

const { REACT_APP_ENV_BADGE_TEXT = '' } = process.env;
const xOffset = parseInt(REACT_APP_ENV_BADGE_TEXT.length * 8 * -1);
const yOffset = 20;

export default function BadgeEnv({ children }) {
  return (
    <Badge offset={[xOffset, yOffset]} count={REACT_APP_ENV_BADGE_TEXT}>
      {children}
    </Badge>
  );
}
