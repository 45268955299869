import api from './ApiService';
import qs from 'query-string';

export async function getCompanies(query) {
  const { data } = await api.get(`companies?${qs.stringify(query)}`);
  return data;
}

export async function getCompany(id) {
  const { data } = await api.get(`companies/${id}`);
  return data;
}

export async function deleteCompany(id) {
  await api.delete(`companies/${id}`);
  return true;
}

export async function postCompany(params) {
  await api.post(`companies`, params);
  return true;
}

export async function putCompany(id, params) {
  await api.put(`companies/${id}`, params);
  return true;
}

export async function getAllCompany() {
  const { data } = await api.get('companies-all');
  return data;
}