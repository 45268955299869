import React from 'react';
import { HomeOutlined, TeamOutlined, SafetyOutlined, LogoutOutlined, PicLeftOutlined, BankOutlined, UnlockOutlined } from '@ant-design/icons';

const style = { fontSize: '18px' };

//Icons: https://ant.design/components/icon/
const routes = [
  {
    group_key: 'main',
    language_key: 'menu.main',
    menus: [
      {
        menu_key: 'dashboard',
        path: '/dashboard',
        language_key: 'menu.dashboard',
        icon: <HomeOutlined style={style} />,
        childs: [],
      },
      {
        menu_key: 'companies',
        path: '/company',
        language_key: 'menu.company',
        icon: <BankOutlined style={style} />,
        permission: 'companies.show',
        childs: [],
      },
      {
        menu_key: 'process-settings',
        language_key: 'menu.processSetting',
        icon: <i class="icon icon-all-contacts" style={style} />,
        permission: 'processSettings.menu',
        childs: [
          {
            sub_menu_key: 'admin-process-settings',
            path: '/process-settings',
            language_key: 'menu.adminProcessSetting',
            permission: 'processSettings.show',
          },
          {
            sub_menu_key: 'log-process-settings',
            path: '/log-process-settings',
            language_key: 'menu.logProcessSetting',
            permission: 'processSettings.logs',
          },
        ],
      },
      {
        menu_key: 'encryptions',
        path: '/encryptions',
        language_key: 'menu.encryption',
        icon: <UnlockOutlined style={style} />,
        permission: 'encryptions.show',
        childs: [],
      },
      {
        menu_key: 'users',
        path: '/users',
        language_key: 'menu.users',
        icon: <TeamOutlined style={style} />,
        permission: 'users.show',
        childs: [],
      },
      {
        menu_key: 'roles',
        path: '/roles',
        language_key: 'menu.roles',
        icon: <SafetyOutlined style={style} />,
        permission: 'roles.show',
        childs: [],
      },
      {
        menu_key: 'logout',
        path: '/logout',
        language_key: 'menu.logout',
        icon: <LogoutOutlined style={style} />,
        childs: [],
      },
    ],
  },
];

export default routes;
