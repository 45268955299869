import { SIGNIN_USER_SUCCESS } from '../../constants/ActionTypes';

const INIT_STATE = {
  token: null,
  type: 'Bearer',
  expiresIn: null,
  expiresInMs: null,
};

export default function Auth(state = INIT_STATE, action) {
  switch (action.type) {
    case SIGNIN_USER_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
