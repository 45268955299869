import { call, put, takeLatest } from 'redux-saga/effects';
import { SIGNIN_USER, SIGNOUT_USER, FORGOT_PASSWORD, RECOVERY_PASSWORD } from '../../constants';
import {
  showLoading,
  userSignInSuccess,
  hideLoading,
  switchLanguageAfterLogin,
  updateProfileSuccess,
  onNavStyleChangeAfterLogin,
  onLayoutTypeChangeAfterLogin,
  setThemeTypeAfterLogin,
  showSuccess,
} from '../../redux/actions';
import { login, logout, forgotPasswordRequest, recoveryPasswordRequest } from '../../services';
import languageData from '../../containers/Topbar/languageData';
import { defaultLocale } from '../../config';

function* signInUserWithEmailPassword({ payload }) {
  try {
    yield put(showLoading());
    const { email, password } = payload;
    const { data } = yield call(login, email, password);
    const { auth, profile } = data;

    yield put(userSignInSuccess(auth));
    yield put(updateProfileSuccess(profile));
    if (!!profile.settings && !profile.settings.resetToDefault) {
      if (!!profile.settings?.themeType) {
        yield put(setThemeTypeAfterLogin(profile.settings?.themeType));
      }
      if (!!profile.settings?.navStyle) {
        yield put(onNavStyleChangeAfterLogin(profile.settings?.navStyle));
      }
      if (!!profile.settings?.layoutType) {
        yield put(onLayoutTypeChangeAfterLogin(profile.settings?.layoutType));
      }
    }
    const locale = languageData.some((x) => x.locale === profile.locale) ? languageData.find((x) => x.locale === profile.locale) : defaultLocale;
    yield put(switchLanguageAfterLogin(locale));
  } catch {
  } finally {
    yield put(hideLoading());
  }
}

function* forgot({ payload }) {
  try {
    yield put(showLoading());
    const { email } = payload;
    yield call(forgotPasswordRequest, email);
    yield put(showSuccess('app.user.forgot_password_request_sent'));
  } catch {
  } finally {
    yield put(hideLoading());
  }
}

function* recovery({ payload }) {
  try {
    yield put(showLoading());
    yield call(recoveryPasswordRequest, payload);
    yield put(showSuccess('app.user.password_updated'));
  } catch {
  } finally {
    yield put(hideLoading());
  }
}

function* signOut() {
  yield call(logout);
}

export default function* rootSaga() {
  yield takeLatest(SIGNOUT_USER, signOut);
  yield takeLatest(FORGOT_PASSWORD, forgot);
  yield takeLatest(RECOVERY_PASSWORD, recovery);
  yield takeLatest(SIGNIN_USER, signInUserWithEmailPassword);
}
