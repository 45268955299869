import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Popover } from 'antd';
import { userSignOut, toggleProfileModal } from '../../redux/actions';
import IntlMessages from '../../util/IntlMessages';

export default function UserProfile() {
  const dispatch = useDispatch();
  let { firstName, lastName } = useSelector(({ profile }) => profile);
  firstName = firstName ?? [''];
  lastName = lastName ?? [''];

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li onClick={() => dispatch(toggleProfileModal())}>
        <IntlMessages id="app.user.my_account" />
      </li>
      <li onClick={() => dispatch(userSignOut())}>
        <IntlMessages id="app.user.logout" />
      </li>
    </ul>
  );

  return (
    <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
      <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
        <Avatar className="gx-size-40 gx-pointer gx-mr-3">
          {firstName[0]}
          {lastName[0]}
        </Avatar>
        <span className="gx-avatar-name">
          {firstName}
          <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
        </span>
      </Popover>
    </div>
  );
}
