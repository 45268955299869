import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { ConfigProvider, message } from 'antd';
import { IntlProvider } from 'react-intl';
import AppLocale from '../../lngProvider';
import LoggedApp from './LoggedApp';
import Login from '../Auth/Login';
import Logout from '../Auth/Logout';
import ForgetPassword from '../Auth/ForgetPassword';
import RecoveryPassword from '../Auth/RecoveryPassword';
import EmailConfirmation from '../Auth/EmailConfirmation';
import { clearSuccess, clearError } from '../../redux/actions';
import { defaultHomePath } from '../../config';
import { translateNotification } from '../../util/IntlMessages';
import { LAYOUT_TYPE_BOXED, LAYOUT_TYPE_FRAMED, LAYOUT_TYPE_FULL } from '../../constants/ThemeSetting';

const RestrictedRoute = ({ component: Component, location, isLogged, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isLogged ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

const App = () => {
  const dispatch = useDispatch();
  const { locale, layoutType } = useSelector(({ settings }) => settings);
  const { error, success } = useSelector(({ common }) => common);
  const { token } = useSelector(({ auth }) => auth);
  const { initURL } = useSelector(({ profile }) => profile);
  const isLogged = !!token;

  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  useEffect(() => {
    setLayoutType(layoutType);
  }, [layoutType]);

  useEffect(() => {
    if (!!success) {
      message.success(translateNotification(success));
      setTimeout(() => dispatch(clearSuccess()), 300);
    }
  }, [success, dispatch]);

  useEffect(() => {
    if (!!error) {
      message.error(translateNotification(error));
      setTimeout(() => dispatch(clearError()), 300);
    }
  }, [error, dispatch]);

  const setLayoutType = (layoutType) => {
    if (layoutType === LAYOUT_TYPE_FULL) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('full-layout');
    } else if (layoutType === LAYOUT_TYPE_BOXED) {
      document.body.classList.remove('full-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('boxed-layout');
    } else if (layoutType === LAYOUT_TYPE_FRAMED) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('full-layout');
      document.body.classList.add('framed-layout');
    }
  };

  useEffect(() => {
    //root redirect
    if (location.pathname === '/') {
      if (!isLogged) {
        history.push('/login');
      } else if (initURL === '' || initURL === '/' || initURL === '/login') {
        history.push(defaultHomePath);
      } else {
        history.push(initURL);
      }
    }
  }, [isLogged, initURL, location, history]);

  const currentAppLocale = AppLocale[locale.locale];

  return (
    <ConfigProvider locale={currentAppLocale.antd}>
      <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/logout" component={Logout} />
          <Route exact path="/forgot" component={ForgetPassword} />
          <Route exact path="/recovery/:recoveryPasswordToken" component={RecoveryPassword} />
          <Route exact path="/confirm-email/:confirmationEmailToken" component={EmailConfirmation} />
          <RestrictedRoute path={`${match.url}`} isLogged={isLogged} location={location} component={LoggedApp} />
        </Switch>
      </IntlProvider>
    </ConfigProvider>
  );
};

export default memo(App);
