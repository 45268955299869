import api from './ApiService';
import qs from 'query-string';

export async function getProcessSettings(query) {
  const { data } = await api.get(`process-settings?${qs.stringify(query)}`);
  return data;
}

export async function getProcessSetting(id) {
  const { data } = await api.get(`process-settings/${id}`);
  const resp = data;
  return resp.data;
}

export async function deleteProcessSetting(id) {
  await api.delete(`process-settings/${id}`);
  return true;
}

export async function postProcessSetting(params) {
  await api.post(`process-settings`, params);
  return true;
}

export async function putProcessSetting(id, params) {
  await api.put(`process-settings/${id}`, params);
  return true;
}

export async function getAllProcessSettings() {
  const { data } = await api.get('process-settings-all');
  return data;
}
