import React from 'react';
import { Layout } from 'antd';
import { useRouteMatch } from 'react-router-dom';
import Sidebar from '../Sidebar/index';
import Topbar from '../Topbar/index';
import { footerText } from '../../config';
import App from '../../views';
import { ProfileModal } from '../Modals';

const { Content, Footer } = Layout;

export default function LoggedApp() {
  const match = useRouteMatch();

  return (
    <Layout className="gx-app-layout">
      <Sidebar />
      <Layout>
        <Topbar />
        <Content className="gx-layout-content">
          <App match={match} />
        </Content>
        <Footer>
          <ProfileModal />
          <div className="gx-layout-footer-content">
            {footerText} <small>(v{process.env.REACT_APP_VERSION})</small>
          </div>
        </Footer>
      </Layout>
    </Layout>
  );
}
