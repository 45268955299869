import React from 'react';
import { Layout } from 'antd';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toggleCollapsedSideNav } from '../../redux/actions';
import UserInfo from '../../components/UserInfo';
import { NAV_STYLE_DRAWER, NAV_STYLE_FIXED, NAV_STYLE_MINI_SIDEBAR, TAB_SIZE } from '../../constants/ThemeSetting';
import BadgeEnv from '../../components/BadgeEnv';
import { logoTransparent } from '../../assets/images';

const { Header } = Layout;

export default function Topbar() {
  const { width, navCollapsed, navStyle } = useSelector(({ settings }) => settings);
  const dispatch = useDispatch();

  return (
    <Header>
      {navStyle === NAV_STYLE_DRAWER || ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) && width < TAB_SIZE) ? (
        <div className="gx-linebar gx-mr-3">
          <i
            className="gx-icon-btn icon icon-menu"
            onClick={() => {
              dispatch(toggleCollapsedSideNav(!navCollapsed));
            }}
          />
        </div>
      ) : null}

      {width > TAB_SIZE && (
        <>
          <Link className="gx-d-block gx-d-lg-none gx-pointer" to="/">
            <img alt="" src={logoTransparent} />
          </Link>
        </>
      )}

      <ul className="gx-header-notifications gx-ml-auto">
        <li className="gx-user-nav">
          <BadgeEnv>
            <UserInfo />
          </BadgeEnv>
        </li>
      </ul>
    </Header>
  );
}
