import React from 'react';
import { Button, Input, Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { userForgotPassword } from '../../redux/actions';
import IntlMessages from '../../util/IntlMessages';
import CircularProgress from '../../components/CircularProgress/index';
import { logoImg } from '../../assets/images';

export default function ForgetPassword() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { loading } = useSelector(({ common }) => common);

  const onFinish = (values) => {
    dispatch(userForgotPassword(values));
  };

  return (
    <div className="gx-app-login-wrap" style={{ backgroundColor: '#DFF2F1' }}>
      <div style={{ height: '50vh', textAlign: 'center' }}>
        <div className="gx-app-login-container" style={{ marginTop: 100 }}>
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg" />
              <div className="gx-app-logo-wid">
                <h1>
                  <IntlMessages id="app.user.forgot_password" />
                </h1>
                <p>
                  <IntlMessages id="app.user.forgot_password_description" />
                </p>
              </div>
              <div className="gx-app-logo">
                <img alt="example" style={{ maxWidth: 200 }} src={logoImg} />
              </div>
            </div>
            <div className="gx-app-login-content">
              <Form form={form} initialValues={{ email: '' }} name="login-form" onFinish={onFinish} className="gx-signin-form gx-form-row0">
                <Form.Item rules={[{ required: true, message: 'Email inválido.' }]} name="email">
                  <Input required type="email" placeholder="Email" />
                </Form.Item>
                <p className="gx-mt-2">
                  <Link to="/login">
                    <span className="gx-link">
                      <IntlMessages id="app.user.back_to_login" />
                    </span>
                  </Link>
                </p>
                <Form.Item>
                  <Button type="primary" className="gx-mt-4" htmlType="submit">
                    <IntlMessages id="app.user.post_forgot_password" />
                  </Button>
                </Form.Item>
              </Form>
            </div>

            {loading && (
              <div className="gx-loader-view">
                <CircularProgress />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
