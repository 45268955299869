import React, { useEffect } from 'react';
import { Button, Input, Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { userSignIn } from '../../redux/actions';
import IntlMessages from '../../util/IntlMessages';
import CircularProgress from '../../components/CircularProgress/index';
import { logoImg } from '../../assets/images';

export default function Login() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { loading } = useSelector(({ common }) => common);
  const { token } = useSelector(({ auth }) => auth);
  const { initURL } = useSelector(({ profile }) => profile);
  const history = useHistory();

  useEffect(() => {
    if (!!token) {
      history.push(initURL); //Redirect logged user
    }
  }, [token, history, initURL]);

  const onFinish = (values) => {
    dispatch(userSignIn(values));
  };

  return (
    <div className="gx-app-login-wrap" style={{ backgroundColor: '#DFF2F1' }}>
      <div style={{ height: '50vh', textAlign: 'center' }}>
        <div className="gx-app-login-container" style={{ marginTop: 100 }}>
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg" />
              <div className="gx-app-logo-wid">
                <h1>
                  <IntlMessages id="app.user.signIn" />
                </h1>
                <p>
                  <IntlMessages id="app.user.by_signing" />
                </p>
              </div>
              <div className="gx-app-logo">
                <img alt="example" style={{ maxWidth: 200 }} src={logoImg} />
              </div>
            </div>
            <div className="gx-app-login-content">
              <Form form={form} initialValues={{ email: '' }} name="login-form" onFinish={onFinish} className="gx-signin-form gx-form-row0">
                <Form.Item rules={[{ required: true, message: 'Email inválido.' }]} name="email">
                  <Input required type="email" placeholder="Email" />
                </Form.Item>
                <Form.Item rules={[{ required: true, message: 'Informe sua senha.' }]} name="password">
                  <Input type="password" required placeholder="Password" />
                </Form.Item>
                <p>
                  <Link to="/forgot">
                    <span className="gx-link">
                      <IntlMessages id="app.user.forgot_password" />
                    </span>
                  </Link>
                </p>
                <Form.Item>
                  <Button type="primary" className="gx-mb-0" htmlType="submit">
                    <IntlMessages id="app.user.signIn" />
                  </Button>
                </Form.Item>
              </Form>
            </div>

            {loading && (
              <div className="gx-loader-view">
                <CircularProgress />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
