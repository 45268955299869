import api from './ApiService';
import qs from 'query-string';

export const STATUS_ERROR = "ERROR";

export async function getJobsByProcessSetting(query) {
  const { data } = await api.get(`log-process-settings?${qs.stringify(query)}`);
  return data;
}

export function getItensStatusProcess() {
  return [{ id: "Todos", name: "Todos" }, { id: "ERROR", name: "Erro" }, { id: "PENDING", name: "Pendente" }, { id: "QUEUED", name: "Em fila" }, { id: "PROCESSING", name: "Em processamento" }, { id: "PROCESSED", name: "Processado" }];
}

export function getItensStatusJob() {
  return [{ id: "Todos", name: "Todos" }, { id: "ERROR", name: "Erro" }, { id: "PENDING", name: "Pendente" }, { id: "QUEUED", name: "Em fila" }, { id: "PROCESSING", name: "Em processamento" }, { id: "PROCESSED", name: "Processado" }];
}

export async function postScheduleProcess(params) {
  // const { data } = await api.get(`log-process-settings?${qs.stringify(query)}`);
  // return data;
  await api.post(`schedule-process-settings`, params);
  return true;
}