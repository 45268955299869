import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import Nprogress from 'nprogress';
import 'nprogress/nprogress.css';
import NotFoundPage from './Errors/404';

const ImportingComponent = () => {
  useEffect(() => {
    Nprogress.start();
    return () => {
      Nprogress.done();
    };
  }, []);
  return null;
};

const Dashboard = lazy(() => import('./Dashboard'));
const Users = lazy(() => import('./Users'));
const UserForm = lazy(() => import('./Users/Form'));
const UserShow = lazy(() => import('./Users/Show'));
const Roles = lazy(() => import('./Roles'));
const RolesForm = lazy(() => import('./Roles/Form'));
const RolesShow = lazy(() => import('./Roles/Show'));
const Company = lazy(() => import('./Company'));
const CompanyForm = lazy(() => import('./Company/Form'));
const CompanyShow = lazy(() => import('./Company/Show'));
const ProcessSetting = lazy(() => import('./ProcessSetting'));
const ProcessSettingForm = lazy(() => import('./ProcessSetting/Form'));
const LogProcessSetting = lazy(() => import('./LogProcessSetting'));
const EncryptionForm = lazy(() => import('./Encryption/Form'));

export default function App({ match }) {
  return (
    <div className="gx-main-content-wrapper">
      <Suspense fallback={<ImportingComponent />}>
        <Switch>
          <Route path={`${match.url}dashboard`} component={Dashboard} />
          <Route path={`${match.url}company`} component={Company} />
          <Route path={`${match.url}users/create`} component={UserForm} />
          <Route path={`${match.url}users/:id/edit`} component={UserForm} />
          <Route path={`${match.url}users/:id`} component={UserShow} />
          <Route path={`${match.url}users`} component={Users} />
          <Route path={`${match.url}roles/create`} component={RolesForm} />
          <Route path={`${match.url}roles/:id/edit`} component={RolesForm} />
          <Route path={`${match.url}roles/:id`} component={RolesShow} />
          <Route path={`${match.url}roles`} component={Roles} />
          <Route path={`${match.url}companies/create`} component={CompanyForm} />
          <Route path={`${match.url}companies/:id/edit`} component={CompanyForm} />
          <Route path={`${match.url}companies/:id`} component={CompanyShow} />
          <Route path={`${match.url}companies`} component={Company} />
          <Route path={`${match.url}process-settings/create`} component={ProcessSettingForm} />
          <Route path={`${match.url}process-settings/:id/edit`} component={ProcessSettingForm} />
          <Route path={`${match.url}process-settings`} component={ProcessSetting} />
          <Route path={`${match.url}log-process-settings`} component={LogProcessSetting} />
          <Route path={`${match.url}encryptions`} component={EncryptionForm} />
          <Route component={NotFoundPage} />
        </Switch>
      </Suspense>
    </div>
  );
}
