// Customizer consts
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW_WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH_LANGUAGE';
export const SWITCH_LANGUAGE_AFTER_LOGIN = 'SWITCH_LANGUAGE_AFTER_LOGIN';

//Common consts
export const SHOW_SUCCESS = 'SHOW_SUCCESS';
export const CLEAR_SUCCESS = 'CLEAR_SUCCESS';
export const SHOW_ERROR = 'SHOW_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const SHOW_LOADING = 'SHOW_LOADING';
export const HIDE_LOADING = 'HIDE_LOADING';

//Auth consts
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RECOVERY_PASSWORD = 'RECOVERY_PASSWORD';

//Profile consts
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const TOGGLE_PROFILE_MODAL = 'TOGGLE_PROFILE_MODAL';
