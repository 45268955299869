import api from './ApiService';

export async function getEncryption(login, password) {
  const { data } = await api.get(`encryptions/${login}/${password}`);
  return data;
}

export async function postDecryption(valueEncrypt) {
  const { data } = await api.post(`encryptions`, valueEncrypt);
  return data;
}
