import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import Settings from './Settings';
import Auth from './Auth';
import Common from './Common';
import Profile from './Profile';

const createRootReducer = (history) =>
  combineReducers({
    settings: Settings,
    auth: Auth,
    common: Common,
    profile: Profile,
    router: connectRouter(history),
  });

export default createRootReducer;
