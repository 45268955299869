import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Popover } from 'antd';
import { userSignOut, toggleProfileModal } from '../../redux/actions';
import IntlMessages from '../../util/IntlMessages';

export default function UserInfo() {
  const dispatch = useDispatch();
  let { firstName, lastName } = useSelector(({ profile }) => profile);
  firstName = firstName ?? [''];
  lastName = lastName ?? [''];

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li onClick={() => dispatch(toggleProfileModal())}>
        <IntlMessages id="app.user.my_account" />
      </li>
      <li onClick={() => dispatch(userSignOut())}>
        <IntlMessages id="app.user.logout" />
      </li>
    </ul>
  );

  return (
    <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions} trigger="click">
      <Avatar className="gx-size-40 gx-pointer gx-mr-3">
        {firstName[0]}
        {lastName[0]}
      </Avatar>
    </Popover>
  );
}
